<template>
  <div>
    <div class="relative w-full max-w-6xl mx-auto my-3">
      <div class="w-full my-3">
        <div class="my-3 relative" style="min-height: 400px">

          <overly class="rounded" background="bg-blue-100" v-if="loading">
            <spinner class="text-red-600 h-10 w-10"></spinner>
            Loading...
          </overly>

          <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4 py-4 w-full">

            <program-item-on-profile
                v-for="participant in courses"
                @handle-candidate-type-update="handleCandidateTypeUpdate( participant.program_id )"
                :participant="participant"
                :key="participant.id"
            />

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentCourseItem from "../../courses/components/student-course-item";
import Overly from "../../../../components/overly";
import Spinner from "../../../../components/spinner";
import ProgramItemOnProfile from "./components/program-item-on-profile";

export default {
  name: "index",
  components: {ProgramItemOnProfile, Spinner, Overly, StudentCourseItem},

  data(){
    return {
      courses: [],
      loading: true
    }

  },

  created() {
    this.$emit('loaded', true)

    this.$store.dispatch( 'batch/getStudentCourses', {
      params: {
        branch_courses_only: 'false',
        with: 'batch-and-course,service-point-and-branch'
      },
      success: ({data}) => {
        this.courses = data.courses;
      },
      error:(response) => { }
    }).finally( () => this.loading = false )
  }

};

</script>

<style scoped>
</style>
