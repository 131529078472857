<template>

  <div class="p-4 border rounded-sm shadow flex flex-col justify-between" :title="`${participant.branchName} (${participant.servicePointName})`">

    <div class="w-full text-center">
      <img class="w-full h-auto" :src="participant.programImage" :alt="participant.batchName" />
      <div class="py-4">
        <h3 class="font-bold text-lg">{{ participant.displayName }}</h3>
        <p class="text-gray-900 font-semibold text-lg mt-2">Course : {{ participant.courseName }} {{ participant.batchYear }}</p>
      </div>
    </div>

    <div class="flex justify-between items-center gap-3">
      <payment-link  class="py-3 relative w-full" :participant="participant" v-if="participant.due > 0"/>

      <div class="w-full flex  justify-between flex-wrap" >
        <btn class="py-3 w-full" variant="success" :router-path="`/my/courses/${participant.programId}/exams`"  >
          Exams
        </btn>
      </div>
      
      <btn class="py-3 w-full" variant="info" :router-path="{name:'CourseSchedules', params:{ programId:participant.programId }}"  >
        Schedules
      </btn>
    </div>

  </div>

</template>

<script>
import Btn from "../../../../components/btn.vue";
import PaymentLink from "./payment-link";
export default {
  name: "student-course-item",
  components: {PaymentLink, Btn},
  props: { participant: Object},
  computed:{
    detailsLink(){

      const batchNameSlug = String( this.participant.batchName )
          .replace(/( |_)/ig,'-')
          .toLowerCase();

      const courseNameSlug = String( this.participant.courseName )
          .replace(/( |_)/ig,'-')
          .toLowerCase();

      return `/courses/${this.participant.programId}/${ courseNameSlug }/${batchNameSlug}`;
    },
  },
  methods: {
    paymentPercent(price, value){
      let percent = Math.round((value/price) * 100);
      return `width: ${percent}%`;
    }
  },
  created() {
    this.programItem = this.participant;
  },
  updated() {
    this.programItem = this.participant;
  }
}
</script>

<style scoped>

</style>