<template>

  <div class="p-4 border rounded-sm shadow flex flex-col justify-between relative" >

    <div class="w-full text-center">
      <div class="py-4">
        <h3 class="font-bold text-lg">{{ participant.displayName }}</h3>
        <p class="text-gray-600 font-semibold text-base mt-2">Course : {{ participant.courseName }}</p>
        <p class="text-gray-600 font-semibold text-sm mt-2">
          <span>Candidate Type : </span>
          <span
            :class="{
              'text-gray-400 ':dataIsNull,
              'text-green-600': candidate_type == 1 && !dataIsNull,
              'text-red-700': candidate_type == 2 && !dataIsNull
            }"
          >{{ candidate_type_text || 'Not set' }}</span>
        </p>
      </div>
    </div>

    <div class="flex justify-between items-center gap-3 ">

      <div class="h-16 bg-opacity-95 text-center w-full absolute block top-0 left-0"
           v-show="message"
           :class="{ 'bg-green-100':update_status == 200, 'bg-red-100': update_status != 200 }"
      >
        <div class="h-full w-full flex items-center justify-center">
          <div :class="{ 'text-green-600':update_status == 200, 'text-red-600': update_status != 200 }"
               class="font-semibold"
               v-html="message"
          />
        </div>
      </div>

      <div class="w-full flex h-16 px-2 justify-evenly gap-2 items-center"  >
<!--      <div class="w-full flex h-16 px-2 justify-center gap-2 items-center" v-if="dataIsNull" >-->

        <div class="w-full bg-green-100 py-2 flex justify-center items-center" v-if="candidate_type_edit_enabled">

          <label class="px-2">
            <input type="radio" name="candidate_type" value="1" v-model="candidate_type"> First Timer
          </label>
          <label class="px-2">
            <input type="radio" name="candidate_type" value="2" v-model="candidate_type"> Second Timer
          </label>
          <btn variant="success" @click="confirm_candidate_type" :disabled="!candidate_type || updating === true" class="mr-2">Confirm</btn>

          <btn variant="warning" @click="candidate_type_edit_enabled = false; candidate_type = null; hideMessage()" >Cancel</btn>
        </div>

        <template v-if="!candidate_type_edit_enabled ">
          <btn variant="info" @click="candidate_type_edit_enabled = !candidate_type_edit_enabled" v-if="dataIsNull">
            Update Candidate Type
          </btn>

          <btn variant="success" :router-path="`/my/courses/${participant.programId}/exams`"  >
            Exams
          </btn>
        </template>

      </div>

    </div>

  </div>

</template>

<script>
import Btn from "../../../../../components/btn";
export default {
  name: "program-item-on-profile",
  components: {Btn},
  data: function (){
    return {
      candidate_type_edit_enabled: false,
      candidate_type: null,
      candidate_type_text: null,
      dataIsNull: false,
      message: null,
      update_status: 200,
      timeoutCountdown: 0,
      updating: false,
    }
  },
  props: {
    participant: {
      type:Object,
      required:true
    }
  },
  created() {
    this.candidate_type = this.participant.candidateType;
    this.candidate_type_text = this.participant.candidateTypeText;
    this.dataIsNull = this.candidate_type == null;
  },
  methods: {
    confirm_candidate_type(){
      //alert( this.candidate_type );

      const candidateType = Number( this.candidate_type );

      if( this.participant.programId && [1,2].indexOf( candidateType ) > -1 ) {

        this.updating = true;

        this.$store.dispatch( 'batch/updateCandidateType', {

          programId: this.participant.programId,
          candidateType: candidateType,

          success:( {data} ) => {
            console.log( 'DD', data );
            //alert( 'Success' );
            this.update_status = 200;
            this.candidate_type_edit_enabled = false;
            this.candidate_type = data.updatedType;
            this.showMessage( data.message, 3000 );
            if( this.candidate_type_text = data.updatedTypeText ) {
              this.dataIsNull = false;
            }
          },

          error: ({data, status} ) => {
            this.update_status = status;
            this.showMessage( data.message )
          }

        }).finally( () => this.updating = false );

      }

    },
    showMessage( msg, timeout = 10000 ){
      this.message =  msg ;
      if( timeout ) {
        clearTimeout( this.timeoutCountdown );
        this.timeoutCountdown = setTimeout( this.hideMessage, timeout );
      }
    },
    hideMessage(){
      this.message = null;
      clearTimeout( this.timeoutCountdown );
    }
  }
}
</script>

<style scoped>

</style>